<template>
  <ComingSoon />
</template>

<script>
import ComingSoon from '@/components/ComingSoon.vue'

export default {
  components: {
    ComingSoon,
  },
}
</script>
